import i18n from 'i18next';
import korean from './locales/kiosk-ko.json';
import english from './locales/kiosk-eng.json';
import japan from './locales/kiosk-jpn.json';
import china from './locales/kiosk-chn.json';
import { initReactI18next } from 'react-i18next';

const resources = {
	eng: {
		translation: english,
	},
	jpn: {
		translation: japan,
	},
	ko: {
		translation: korean,
	},
	chn: {
		translation: china,
	},
};

i18n.use(initReactI18next).init({
	resources: resources,
	lng: 'ko',
});

export default i18n;
