import { callAsync } from './callAsync';
import { rootUrl } from './const';
import {
	getAllCallsInThreeMinute,
	getAllGuestVisitRecentCalledInAllStoreToday,
	getAllGuestVisitsAsyncWithStatus,
	getAllGuestVisitsInAllStoreToday,
} from './getAllGuestVisitsAsyncWithStatus';
import {
	getVisitInfoBy입장인원정보,
	registerGuestVisitAsync,
	registerGuestVisitV2Async,
	registerGuestVisitV2지류티켓Async,
} from './registerGuestVisitAsync';
import { changeGuestVisitStatus } from './changeGuestVisitStatus';
import { getRecentGuestVisitAsync } from './getRecentGuestVisitAsync';
import { getGuestVisitDetailAsync } from './getGuestVisitDetailAsync';
import { searchGuestVisitsAsync } from './searchGuestVisitsAsync';
import { searchGuestVisitsInfiniteAsync } from './searchGuestVisitsInfiniteAsync';
import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';

const mergeGuestVisit =
	(rootUrl: string) =>
	async ({
		storeId,
		posId,
		from,
		to,
	}: {
		storeId: number;
		posId: number;
		from: number;
		to: number;
	}) =>
		(
			await posAxiosClient.post<GuestVisitType>(
				`${rootUrl}/guest-visit/store/${storeId}/pos/${posId}/merge`,
				null,
				{ params: { from, to } }
			)
		).data;

export const WaiverApis = {
	getAllGuestVisitsToday: getAllGuestVisitsAsyncWithStatus(rootUrl),
	getAllGuestVisitsInAllStoreToday: getAllGuestVisitsInAllStoreToday(rootUrl),
	getAllGuestVisitRecentCalledInAllStoreToday: getAllGuestVisitRecentCalledInAllStoreToday(rootUrl),
	callLatestGuestAsync: callAsync(rootUrl),
	registerGuestVisitAsync: registerGuestVisitAsync(rootUrl),
	registerGuestVisitV2Async: registerGuestVisitV2Async(rootUrl),
	registerGuestVisitV2지류티켓Async: registerGuestVisitV2지류티켓Async(rootUrl),
	getVisitInfoBy입장인원정보: getVisitInfoBy입장인원정보,
	getRecentGuestVisitAsync: getRecentGuestVisitAsync(rootUrl),
	changeGuestVisitStatus: changeGuestVisitStatus(rootUrl),
	getGuestVisitDetail: getGuestVisitDetailAsync(rootUrl),
	searchGuestVisits: searchGuestVisitsAsync(rootUrl),
	searchGuestVisitInfinite: searchGuestVisitsInfiniteAsync(rootUrl),
	getAllCallsInThreeMinute: getAllCallsInThreeMinute(rootUrl),
	mergeGuestVisit: mergeGuestVisit(rootUrl),
};
