import { WarningOutlined } from '@ant-design/icons';
import { KioskBoardItem } from '@kinderlabs-pos/feature/kiosk-board';
import { KioskBoardItemInfoWithDetailType, KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { getI18nString, getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Stack, Typography, alpha } from '@mui/material';
import { RefObject, useState } from 'react';
import { KioskBoardNormalItemOptionDialog } from './KioskBoardItem/KioskBoardNormalItemOptionDialog';
import { KioskBoardSetItemSelectDialog } from './KioskBoardItem/KioskBoardSetItemSelectDialog';
import { useAtomValue } from 'jotai';
import { useKioskTranslation } from '../../../const/useKioskTranslation';

export const KioskBoardProductionItem = ({
	itemInfo,
	kioskInfo,
	kioskBoardalertMessage,
	boardItemRef,
}: {
	itemInfo: KioskBoardItemInfoWithDetailType;
	kioskInfo: KioskInfoType;
	kioskBoardalertMessage?: string;
	boardItemRef?: RefObject<HTMLDivElement>;
}) => {
	const { kioskI18N } = useKioskTranslation();

	const cartReducer = OrderState.cart.useDispatcher();
	const handleClick장바구니추가 = () => {
		if (itemInfo.productInfo)
			cartReducer({
				type: 'PRODUCTS',
				subAction: {
					type: 'ADD',
					cartLineId: getUuidV4(),
					productInfo: itemInfo.productInfo,
					imageUrl: itemInfo.imageUrl,
					options: [],
				},
			});
	};
	const [selectedItemInfo, setSelectedItemInfo] = useState<KioskBoardItemInfoWithDetailType>();

	const alertMessage =
		getI18nString({
			ko: itemInfo.alertMessage ?? undefined,
			i18nString: itemInfo.i18nAlertMessage,
			i18n: kioskI18N,
		}) ?? kioskBoardalertMessage;

	const customConfirm = useConfirm();

	const handleClick옵션없는일반상품 = () =>
		alertMessage
			? customConfirm(
					<Stack
						minWidth={620}
						direction={'row'}
						sx={(theme) => ({
							p: 1,
							color: theme.palette.warning.main,
							bgcolor: alpha(theme.palette.warning.light, 0.1),
						})}
						spacing={1}>
						<Typography
							sx={{ color: 'inherit' }}
							variant='h6'>
							<WarningOutlined />
						</Typography>
						<Typography
							sx={{ color: 'inherit', whiteSpace: 'pre-wrap' }}
							variant='h6'>
							{alertMessage}
						</Typography>
					</Stack>,
					() => {
						handleClick장바구니추가();
					},
					undefined
			  )
			: handleClick장바구니추가();

	const hasOptions =
		itemInfo.productInfo?.type === 'SET' || itemInfo.productOptionInfoList.length > 0;
	const is세트상품 = itemInfo.productInfo?.type === 'SET';

	return (
		<WithDialog
			dialog={(open, closeDialog) =>
				selectedItemInfo &&
				(is세트상품 ? (
					// SET 상품
					<KioskBoardSetItemSelectDialog
						itemInfo={selectedItemInfo}
						alertMessage={alertMessage}
						open={open}
						closeDialog={closeDialog}
					/>
				) : (
					// 옵션 상품
					<KioskBoardNormalItemOptionDialog
						itemInfo={selectedItemInfo}
						alertMessage={alertMessage}
						open={open}
						closeDialog={closeDialog}
					/>
				))
			}>
			{(openDialog) => (
				<KioskBoardItem.Base
					i18N={kioskI18N}
					boardItemRef={boardItemRef}
					boardItemInfo={itemInfo}
					onClick={() => {
						if (itemInfo.isSoldOut) return;
						if (hasOptions) {
							setSelectedItemInfo(itemInfo);
							openDialog();
						} else {
							setSelectedItemInfo(undefined);
							handleClick옵션없는일반상품();
						}
					}}
					// actionSets={
					// 	<Stack width={'100%'}>
					// 		<Button
					// 			onClick={() => {
					// 				if (hasOptions) {
					// 					openDialog();
					// 				} else {
					// 					handleClick장바구니추가();
					// 				}
					// 			}}
					// 			fullWidth
					// 			endIcon={<RightOutlined />}
					// 			variant={'outlined'}>
					// 			주문하기
					// 		</Button>
					// 	</Stack>
					// }
				/>
			)}
		</WithDialog>
	);
};
