import { PosCouponTicketApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	PosCouponExcelUploadType,
	PosCouponListFilterType,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../../QueryClient';

const posCouponTicketKeys = createQueryKeys('pos-coupon-ticket', {
	list: ({ params }: { params: PosCouponListFilterType }) => ({
		queryKey: [params],
		queryFn: async () => {
			const res = await PosCouponTicketApis.getListPage({ ...params });
			return res;
		},
	}),
	detail: (couponId?: string) => ({
		queryKey: [couponId],
		queryFn: async () => {
			if (!couponId) return null;
			const res = await PosCouponTicketApis.getOne({ couponId });
			return res;
		},
	}),
});

const useCouponExcelUpdate = () => {
	return QueryClient.useMutation(
		async (itemList: PosCouponExcelUploadType[]) => {
			await PosCouponTicketApis.createCouponExcel(itemList);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDiscardPosCouponInfoList = () => {
	return QueryClient.useMutation(
		async (input: number[]) => {
			await PosCouponTicketApis.updateDiscard({ couponInfoId: input[0] });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useCouponForceUseQuery = () => {
	return QueryClient.useMutation(
		async (couponId: string) => {
			await PosCouponTicketApis.forceUse(couponId);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () =>
	QueryClient.origin.invalidateQueries({ queryKey: posCouponTicketKeys._def });

export const PosCouponTicketState = {
	keys: posCouponTicketKeys,
	invalidateQueries: invalidateQueries,
	useDiscard: useDiscardPosCouponInfoList,
	useExcelUpdateCoupon: useCouponExcelUpdate,
	useForceUse: useCouponForceUseQuery,
};
