import { CategorySearchSpec } from './CategorySearchSpec';
import { Pageable } from '../PageType';
import { BaseInfoType } from './BaseInfoType';
import { CategoryInfoTreeType } from './CategorySearchSpec';
import { DdiziInfoType } from './DdiziInfoType';
import { ExchangeInfoType } from './ExchangeInfoType';

// export const ProductTypeArray = ['DDIZI', 'GOODS', 'FOOD', 'ADDITIONALPAY', 'CUSTOM'] as const;

export const ProductTypeArray = ['DDIZI', 'GOODS', 'FOOD', 'SET', 'ETC', 'OPTION'] as const;
export type ProductType = (typeof ProductTypeArray)[number];
export type AdminProductType = Extract<
	ProductType,
	'DDIZI' | 'GOODS' | 'FOOD' | 'ETC' | 'OPTION' | 'SET'
>;

export const ProductAvailableStoreTypeArray = ['ALL', 'TARGET', 'NO_USAGE'] as const;
export type ProductAvailableStoreType = (typeof ProductAvailableStoreTypeArray)[number];
export const ProductAvailableStoreLabel: Record<ProductAvailableStoreType, string> = {
	ALL: '전매장 사용',
	TARGET: '매장별 사용',
	NO_USAGE: '미 사용',
};

export const StockUnitTypeArray = ['EA', 'BOX'] as const;
export type StockUnitType = (typeof StockUnitTypeArray)[number];
export const AdminStockUnitTypeLabel: Record<StockUnitType, string> = {
	EA: 'EA',
	BOX: 'BOX',
};

export const ProductTypeLabel: Record<ProductType, string> = {
	DDIZI: '입장권',
	GOODS: '굿즈',
	FOOD: 'F&B',
	SET: 'F&B SET',
	OPTION: '옵션',
	ETC: '기타',
	// ADDITIONALPAY: '퇴장정산금액',
	// CUSTOM: '임의상품추가',
};

export const canHaveDdiziInProduct: Record<ProductType, boolean> = {
	DDIZI: true,
	GOODS: false,
	FOOD: false,
	SET: false,
	OPTION: false,
	ETC: false,
};

export const canHaveExchangeCouponInProduct: Record<ProductType, boolean> = {
	DDIZI: true,
	GOODS: true,
	FOOD: true,
	SET: true,
	OPTION: false,
	ETC: true,
};

export const canHaveCategoryInProduct: Record<ProductType, boolean> = {
	DDIZI: true,
	GOODS: true,
	FOOD: true,
	SET: true,
	OPTION: true,
	ETC: true,
};

export const canHaveDiscountInProductBoard: Record<ProductType, boolean> = {
	DDIZI: true,
	GOODS: true,
	FOOD: true,
	SET: true,
	OPTION: false,
	ETC: true,
};

export const canBarcodeScan: Record<ProductType, boolean> = {
	DDIZI: true,
	GOODS: true,
	FOOD: true,
	SET: false,
	OPTION: false,
	ETC: true,
};

export interface ProductInfoVariantType {
	id?: number;
	storeId: number;
	enabled: boolean;
	price?: number;
}

export type BasicUnitType = {
	value: number;
	unit: StockUnitType;
};

export type ProductUnitType = BasicUnitType & { bundle: StockUnitType };

export type ProductStockDataType = {
	orderUnit: BasicUnitType;
	returnUnit: BasicUnitType;
	stockUnit: BasicUnitType;
	productUnit: ProductUnitType;
};

export type OptionGroupListType = {
	id: number;
	name: string;
	description: string;
	optionList: OptionType[];
	isForKitchen: boolean;
	isMultipleCheckable: boolean;
	minSelect: number;
	maxSelect: number;

	i18nName?: {
		eng?: string;
		jpn?: string;
		chn?: string;
	};
	i18nDescription?: {
		eng?: string;
		jpn?: string;
		chn?: string;
	};
};

export type OptionType = {
	id: number;
	name: string;
	price: number;
	i18nName?: {
		eng?: string;
		jpn?: string;
		chn?: string;
	};
};

export type OptionTypeForHistory = {
	id: number;
	name: string;
	price: number;
};

export interface ProductInfoType {
	id: number;
	name: string;
	barcode?: string;
	description?: string;
	price: number;
	rawPrice: number;
	supplyPrice: number;
	categoryId?: number;
	type: ProductType;
	ddiziInfo?: DdiziInfoType;
	taxFree: boolean;
	exchangeInfo?: ExchangeInfoType;
	optionGroupList?: OptionGroupListType[];
	storeBlackList?: number[];
	isForKitchen: boolean;
	isStockManaged: boolean;
	variants: ProductInfoVariantType[];
	productStockData?: ProductStockDataType;
	usedInPosBoard?: boolean;
	usedInKioskBoard?: boolean;

	availableStoreType: ProductAvailableStoreType;
	enabledPriceOverride: boolean;

	i18nName?: {
		eng?: string;
		jpn?: string;
		chn?: string;
	};
	i18nDescription?: {
		eng?: string;
		jpn?: string;
		chn?: string;
	};

	doNotPrintDdizi?: boolean;
}

export const getProductExcelData = (
	row: ProductInfoType & BaseInfoType,
	categoryTree: CategoryInfoTreeType
) => {
	return {
		...row,
		margin: row.price !== 0 ? `${Math.round((100 * (row.price - row.rawPrice)) / row.price)}%` : '',
		topCategory: row.categoryId ? categoryTree.BASE[row.categoryId].topCategoryInfo.name : '-',
		superCategory: row.categoryId ? categoryTree.BASE[row.categoryId].superCategoryInfo.name : '-',
		baseCategory: row.categoryId ? categoryTree.BASE[row.categoryId].baseCategoryInfo.name : '-',
	};
};

export interface AdminProductHistoryIntoType
	extends Omit<ProductInfoType, 'ddiziInfo' | 'exchangeInfo' | 'optionGroupList'> {
	deleted: boolean;
	ddiziInfoVO: ProductInfoType['ddiziInfo'];
	optionGroupList: (Omit<OptionGroupListType, 'optionList'> & {
		optionList: OptionTypeForHistory[];
	})[];
	exchangeInfoVO: {
		name: string;
		description: string;
	};
}

export type AdminProductInfoListFilterType = {
	storeId?: number;
	type?: ProductType;
	// ddiziInfoId?: number;
	page: number;
	categorySpec: CategorySearchSpec;
	size?: number;
	search?: string;
	sort?: Pageable<keyof (ProductInfoType & BaseInfoType)>['sort'];
};

export type AdminCategoryType = {
	id: number;
	name: string;
};

export type AdminCategoryInfoListFilterType = {
	page: number;
	size?: number;
	search?: string;
	sort?: Pageable['sort'];
};

export type AdminCategoryGroupSetInfoListFilterType = {
	page: number;
	size: number;
	search?: string;
	sort?: Pageable['sort'];
};

export type AdminProductBoardInfoType = Pick<ProductInfoType, 'id' | 'name'> &
	Pick<AdminProductInfoListFilterType, 'storeId'> & {
		items?: [
			{
				productInfoId: number;
				color: string;
				discountInfoId: number;
			}
		];
	};
