import { InputLabel, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import * as React from 'react';
import { IMaskInput } from 'react-imask';
import { PhoneNumberInputMask } from '@kinderlabs-pos/ui-components';

export interface ITelephoneFormProps extends Omit<TextFieldProps, 'fullWidth' | 'variant'> {
	changed?: boolean;
	helperText?: string;
	defaultPlaceholder?: string;
	readOnly?: boolean;
}

/**
 * 해당 컴포넌트는 React Imask 를 이용했는지 이 패키지가 TS 에 대한 호환이 별로 좋지 못해 any 를 이용해서 어거지로 붙였음.
 * MUI 공식 가이드에서도 마찬가지로 좋지 않은 TS 호환성을 보여준다.
 * @url https://codesandbox.io/s/9h5ewt?file=/demo.tsx:375-490
 */
export const TelephoneForm: React.FC<ITelephoneFormProps> = ({
	label,
	InputProps,
	changed,
	readOnly = false,
	helperText = 'ㅤ',
	defaultPlaceholder,
	placeholder,
	size = 'small',
	...others
}) => {
	const phoneMask = React.useMemo(() => PhoneNumberInputMask(true), []);

	return (
		<Stack>
			<InputLabel htmlFor='email'>
				<Typography variant={'body2'}>{label}</Typography>
			</InputLabel>
			<TextField
				fullWidth
				size={size}
				{...others}
				placeholder={placeholder || defaultPlaceholder}
				helperText={
					<Typography
						variant='caption'
						component={'span'}
						color={'error'}>
						{helperText}
					</Typography>
				}
				InputProps={{
					...InputProps,
					readOnly: readOnly,
					inputComponent: phoneMask as any,
					autoComplete: 'off',
					style: { height: 80, fontSize: '2.5rem' },
				}}
			/>
		</Stack>
	);
};
