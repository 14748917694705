import { OrderState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { useAtom } from 'jotai';
import { CartActionBoard } from '.';

export const useCartAction = () => {
	const dispatchCart = OrderState.cart.useDispatcher();
	const [selectedCartLine, selectCartLine] = useAtom(CartActionBoard.selectedCartLineAtom);
	console.log(selectedCartLine);

	const confirm = useConfirm();

	const handle상품삭제 = () => {
		if (!selectedCartLine) {
			throw new Error('CartLine 은 1개 선택되어야 합니다.');
		}

		dispatchCart({ type: 'BOARD', subAction: { type: 'DELETE', cartLineId: selectedCartLine.id } });
		selectCartLine(undefined);
	};

	const handle상품추가 = () => {
		if (!selectedCartLine) {
			throw new Error('CartLine 은 1개 선택되어야 합니다.');
		}

		dispatchCart({
			type: 'BOARD',
			subAction: {
				type: 'ADD',
				cartLineType: selectedCartLine.type,
				cartLineId: selectedCartLine.id,
			},
		});
	};

	const handle상품감소 = () => {
		if (!selectedCartLine) {
			throw new Error('CartLine 은 1개 선택되어야 합니다.');
		}

		dispatchCart({
			type: 'BOARD',
			subAction: {
				type: 'MINUS',
				cartLineType: selectedCartLine.type,
				cartLineId: selectedCartLine.id,
			},
		});

		//FIXME
		if (selectedCartLine.quantity === 0) selectCartLine(undefined);
	};

	const handle할인제거 = () => {
		if (!selectedCartLine) {
			throw new Error('CartLine 은 1개 선택되어야 합니다.');
		}

		confirm('해당 상품의 할인을 제거하시겠습니까?', () => {
			dispatchCart({
				type: 'BOARD',
				subAction: {
					type: 'CHANGE_DISCOUNT',
					cartLineType: selectedCartLine.type,
					cartLineId: selectedCartLine.id,
					newDiscountInfo: undefined,
				},
			});

			selectCartLine(undefined);
		});
	};

	return {
		handle상품추가,
		handle상품감소,
		handle상품삭제,
		handle할인제거,
	};
};
