import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {
	CartLineInfoType,
	CartLineProductInfoType,
	CartLineTypeRules,
} from '@kinderlabs-pos/shared-data-type';
import { OrderState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, DialogContent, Unstable_Grid2 as Grid, Typography } from '@mui/material';
import { atom, useAtomValue } from 'jotai';
import { CartDiscountDialog } from '../../dialogs/DiscountAndCoupon/CartDiscountDialog';
import { WithChangeNumberDialog } from '../../dialogs/WithChangeNumberDialog';
import { CartActionResetButton } from './CartActionResetButton';
import { useCartAction } from './useCartAction';
import { CartLineProductActionType } from 'libs/state/src/lib/state/PosOrder/OrderState/CartState/CartLineProductState';

export const CartActionBoard = ({
	EtcCartMenuContent,
}: {
	EtcCartMenuContent: React.FC<{ onComplete: () => void }>;
}) => {
	const selectedCartLine = useAtomValue(CartActionBoard.selectedCartLineAtom);
	const cartLines = useAtomValue(OrderState.cart.selector).lines;

	const cannotChangeQuantity =
		!selectedCartLine ||
		!CartLineTypeRules.canChangeQuantity({
			cartLine: selectedCartLine,
			cartLines: cartLines,
		});
	const { handle상품추가, handle상품감소, handle상품삭제, handle할인제거 } = useCartAction();
	return (
		<Grid
			container
			spacing={1}
			sx={{ p: 0 }}>
			<Grid xs={3}>
				<Button
					fullWidth
					disabled={cannotChangeQuantity}
					size={'large'}
					variant={'outlined'}
					onClick={handle상품추가}>
					<Typography variant='subtitle1'>
						<PlusOutlined />
					</Typography>
				</Button>
			</Grid>
			<Grid xs={3}>
				<Button
					fullWidth
					disabled={cannotChangeQuantity}
					size={'large'}
					variant={'outlined'}
					onClick={handle상품감소}>
					<Typography variant='subtitle1'>
						<MinusOutlined />
					</Typography>
				</Button>
			</Grid>
			<Grid xs={6}>
				<WithChangeNumberDialog selectedCartLine={selectedCartLine}>
					{(openDialog) => (
						<Button
							fullWidth
							size={'large'}
							disabled={cannotChangeQuantity}
							variant={'outlined'}
							onClick={openDialog}>
							<Typography variant='subtitle1'>{'수량 변경'}</Typography>
						</Button>
					)}
				</WithChangeNumberDialog>
			</Grid>
			<Grid xs={6}>
				<Button
					fullWidth
					disabled={!selectedCartLine}
					size={'large'}
					variant={'outlined'}
					onClick={handle상품삭제}>
					<Typography variant='subtitle1'>
						{selectedCartLine?.type === 'PRODUCT' &&
						(selectedCartLine as CartLineProductInfoType).sessionInfo
							? '차감'
							: '삭제'}
					</Typography>
				</Button>
			</Grid>
			<Grid xs={6}>
				<CartActionResetButton
					fullWidth
					size={'large'}
					variant={'outlined'}
					disabled={!cartLines.length}>
					<Typography variant='subtitle1'>{'초기화'}</Typography>
				</CartActionResetButton>
			</Grid>
			<Grid xs={6}>
				<WithDialog
					dialog={(open, closeDialog) => (
						<CartDiscountDialog
							open={open}
							closeDialog={closeDialog}
						/>
					)}>
					{(onClick) => (
						<Button
							fullWidth
							size={'large'}
							disabled={cartLines.length === 0}
							variant={'outlined'}
							onClick={onClick}>
							<Typography variant='subtitle1'>{'상품 할인'}</Typography>
						</Button>
					)}
				</WithDialog>
			</Grid>
			<Grid xs={6}>
				<WithDialog
					dialog={(open, closeDialog) => (
						<WithDialog.XlDialog
							maxWidth={'lg'}
							dialogTitle={'기타 메뉴'}
							open={open}
							closeDialog={closeDialog}
							closeDialogAction={'닫기'}>
							<DialogContent sx={{ minWidth: 360, minHeight: 50 }}>
								<EtcCartMenuContent onComplete={closeDialog} />
							</DialogContent>
						</WithDialog.XlDialog>
					)}>
					{(openDialog) => (
						<Button
							fullWidth
							size={'large'}
							variant={'outlined'}
							onClick={openDialog}>
							<Typography variant='subtitle1'>{'기타 메뉴'}</Typography>
						</Button>
					)}
				</WithDialog>
			</Grid>
		</Grid>
	);
};
CartActionBoard.selectedCartLineAtom = atom<CartLineInfoType | undefined>(undefined);

CartActionBoard.MiniBoard = ({
	handle초기화,
	hasChanged,
}: {
	handle초기화: () => void;
	hasChanged: boolean;
}) => {
	const { handle상품추가, handle상품감소, handle상품삭제, handle할인제거 } = useCartAction();

	const selectedCartLine = useAtomValue(CartActionBoard.selectedCartLineAtom);
	const cartLines = useAtomValue(OrderState.cart.selector).lines;

	const cannotChangeQuantity =
		!selectedCartLine ||
		!CartLineTypeRules.canChangeQuantity({
			cartLine: selectedCartLine,
			cartLines: cartLines,
		});

	return (
		<Grid
			width={'100%'}
			container
			columnSpacing={1}>
			<Grid xs={3}>
				<Button
					fullWidth
					disabled={cannotChangeQuantity}
					size={'large'}
					variant={'outlined'}
					onClick={handle상품추가}>
					<Typography variant='subtitle1'>
						<PlusOutlined />
					</Typography>
				</Button>
			</Grid>
			<Grid xs={3}>
				<Button
					fullWidth
					disabled={cannotChangeQuantity}
					size={'large'}
					variant={'outlined'}
					onClick={handle상품감소}>
					<Typography variant='subtitle1'>
						<MinusOutlined />
					</Typography>
				</Button>
			</Grid>
			<Grid xs={3}>
				<Button
					fullWidth
					disabled={!selectedCartLine}
					size={'large'}
					variant={'outlined'}
					onClick={handle상품삭제}>
					<Typography variant='subtitle1'>{'삭제'}</Typography>
				</Button>
			</Grid>
			<Grid xs={3}>
				<Button
					fullWidth
					size={'large'}
					disabled={!hasChanged}
					variant={'outlined'}
					onClick={handle초기화}>
					<Typography variant='subtitle1'>초기화</Typography>
				</Button>
			</Grid>
		</Grid>
	);
};
